<template>
    <div class="policy-terms page">
        <div class="web-content">
            <div class="policy-title-box">
                <div class="policy-title">
                    {{ $t('PolicyAndTerms.title') }}
                </div>
            </div>
            <div class="policy-content">
                <div class="sub-title">
                    {{ $t('PolicyAndTerms.subtitle') }}
                </div>
                <br>
                <div class="text">
                    {{ $t('PolicyAndTerms.text[0]') }}
                    <span class="color-orange">
                        {{ $t('PolicyAndTerms.text[1]') }}
                    </span>
                </div>
                <br>
                <br>
                <div class="text">
                    <div>{{ $t('PolicyAndTerms.text[2]') }}</div>
                    <ol>
                        <li>
                            {{ $t('PolicyAndTerms.text[3]') }}
                        </li>
                        <li>
                            {{ $t('PolicyAndTerms.text[4]') }}
                        </li>
                        <li>
                            {{ $t('PolicyAndTerms.text[5]') }}
                        </li>
                        <li>
                            {{ $t('PolicyAndTerms.text[6]') }}
                        </li>
                        <li>
                            {{ $t('PolicyAndTerms.text[7]') }}
                        </li>
                        <li>{{ $t('PolicyAndTerms.text[8]') }}</li>
                        <li>
                            {{ $t('PolicyAndTerms.text[9]') }}
                        </li>
                        <li>{{ $t('PolicyAndTerms.text[10]') }}</li>
                        <li>
                            {{ $t('PolicyAndTerms.text[11]') }}
                        </li>
                        <li>
                            {{ $t('PolicyAndTerms.text[12]') }}
                        </li>
                    </ol>
                </div>
                <br>
                <div class="text">
                    <div>{{ $t('PolicyAndTerms.text[13]') }}</div>
                    {{ $t('PolicyAndTerms.text[14]') }}
                </div>
                <br>
                <div class="text">
                    <div>{{ $t('PolicyAndTerms.text[15]') }}</div>
                    <ol>
                        <li>
                            {{ $t('PolicyAndTerms.text[16]') }}
                        </li>
                        <li>
                            {{ $t('PolicyAndTerms.text[17]') }}
                        </li>
                    </ol>
                </div>
                <br>
                <div class="text">
                    <div>{{ $t('PolicyAndTerms.text[18]') }}</div>
                    <ol>
                        <li>
                            {{ $t('PolicyAndTerms.text[19]') }}
                        </li>
                        <li>
                            {{ $t('PolicyAndTerms.text[20]') }}
                        </li>
                    </ol>
                </div>
                <br>
                <div class="text">
                    <div>{{ $t('PolicyAndTerms.text[21]') }}</div>
                    {{ $t('PolicyAndTerms.text[22]') }}
                </div>
                <br>
                <div class="text">
                    <div>{{ $t('PolicyAndTerms.text[23]') }}</div>
                    {{ $t('PolicyAndTerms.text[24]') }}
                </div>
                <br>
                <div class="text">
                    <div>{{ $t('PolicyAndTerms.text[25]') }}</div>
                    <ol>
                        <li>
                            {{ $t('PolicyAndTerms.text[26]') }}
                        </li>
                        <li>{{ $t('PolicyAndTerms.text[27]') }}</li>
                        <li>{{ $t('PolicyAndTerms.text[28]') }}</li>
                        <li>{{ $t('PolicyAndTerms.text[29]') }}</li>
                        <li>
                            {{ $t('PolicyAndTerms.text[30]') }}
                        </li>
                    </ol>
                </div>
                <br>
                <div class="text">
                    <div>{{ $t('PolicyAndTerms.text[31]') }}</div>
                    {{ $t('PolicyAndTerms.text[32]') }}
                </div>
                <br>
                <div class="text">
                    {{ $t('PolicyAndTerms.text[33]') }}
                </div>
                <br>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.policy-terms {
  color: $color-gray;

  .web-content {
    padding: 75px 140px;
  }

  .policy-title-box {
    display: flex;
    justify-content: center;
  }
  .policy-title {
    display: flex;
    font-weight: 700;
    font-size: 28px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.6em;
    padding-bottom: 12px;
    border-bottom: 3px solid $color-orange;
    margin-bottom: 50px;
  }
  .sub-title {
    font-weight: 700;
    font-size: 21px;
    line-height: 1;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
  }

  .content {
    padding-left: 10px;
  }
  .text {
    font-weight: 500;
    font-size: 16px;
    line-height: 2;
    letter-spacing: 0.1em;
  }
}
@media screen and (max-width: 960px) {
  .policy-terms {
    .web-content {
      padding: 65px 60px;
    }

    .policy-title {
      font-size: 21px;
    }

    .sub-title {
      font-size: 16px;
    }

    .text {
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 600px) {
  .policy-terms {
    .web-content {
      padding: 55px 30px;
    }
  }
}
</style>
